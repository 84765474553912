$font-main: Lato, sans-serif;
$font-secondary: 'PT Serif', serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$navy-blue: #2140ff;
$deep-orange-a400: #ff3d00;
$blue-gray-600: #546e7a;
$blue-gray-300: #90a4ae;
$eggshell-white: #fafafa;

$color-main: $navy-blue;
$color-accent: $deep-orange-a400;
$color-background: $eggshell-white;
$color-headings: $blue-gray-600;
$color-paragraphs: $blue-gray-300;

$small: "screen and (min-width: 30rem)"; // 480px
$medium: "screen and (min-width: 48rem)";// 768px
$large: "screen and (min-width: 62rem)"; // 992px
$x-large: "screen and (min-width: 64.85rem)"; // 1038px


$spacing-default: 3rem;

$container-default-width: 90%;
$container-max-width: 120rem; // 1920px

$primary-transition: all .2s ease-in-out;
