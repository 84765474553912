@import '../../App.scss';

.portfolio {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$x-large} {
    padding: 0;
  }
}

.portfolio h2 {
  margin-top: 0;
  margin-bottom: 2rem;
  color: $navy-blue;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: center;

  @media #{$medium} {
    font-size: 3.5rem;
    text-align: left;
  }
}

.portfolio-wrapper {
  display: flex;
  flex-direction: column;

  @media #{$medium} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }

  > a {
    margin-bottom: 40px;

    @media #{$medium} {
      margin-bottom: 0;
    }
  }
}

.portfolio-item {
  border: 1px solid #ccc;
  
  transition: transform .2s ease-in-out;
  
  &:hover {
    box-shadow: 0 0 1rem 0 $blue-gray-600;
    transform: scale(1.1);
  }
}

.portfolio-banner {
  display: flex;
  align-items: center;
  justify-content: center;

 
  max-width: 100%;
  max-height: 100%;

  background-color: #fff;
  border-bottom: 1px solid #ccc;

  @media #{$medium} {
    height: 32rem;
  }
}

.portfolio-title {
  padding: 2rem 0 2rem 3rem;

  background-color: #eee;

  color: $blue-gray-600;
}