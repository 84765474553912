@import '../../App.scss';

.main-nav {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 20px;

  max-width: 1024px;
  margin: 0 auto 30px;

  @media #{$x-large} {
    flex-flow: row nowrap;
    padding-left: 0;
    padding-right: 0;
  }
}

.logo {
  max-width: 60px;
  height: auto;
  text-decoration: none;

  transition: $primary-transition;

  &:hover {
    opacity: .8;
  }
}

.nav-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  
  padding-left: 0;

  list-style-type: none;

  @media #{$x-large} {
    flex-flow: row nowrap;
  }
}

.nav-link {
  margin: 1rem;
  padding: 1rem 2rem;
  display: inline-block;

  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: $font-weight-light;
  letter-spacing: .3rem;
  text-align: center;
  color: $eggshell-white;

  background-color: $navy-blue;

  transition: all 0.2s ease-in;

  @media #{$x-large} {
    margin: 0 0 0 2rem;
    font-size: 1.8rem;

    span { font-size: 1.6rem; }

    &:hover {
      box-shadow: inset 0 0 0 .2rem #fff;
    }
  }

  li {
    display: flex;
    align-items: baseline;
  }

  span {
    display: block;
    font-size: 1.4rem;
    padding-top: .5rem;
    order: -1;
    margin-right: 8px;
  }
}
