@import '../../../App.scss';

#article-content {
  width: $container-default-width;
  max-width: $container-max-width;
  margin: ($spacing-default * 2) auto;
}

.article-title {  
  background-color: white;
  padding: 20px;
  border: 1px solid $navy-blue;
 }

 .article-title > h1,
 .article-title > p {
  margin: 0;
 }

.article-title > h1 {
  font-size: 2.2rem;
  font-weight: $font-weight-light;
  color: $navy-blue;

  @media #{$large} {
    font-size: 4.2rem;
  }
}

.article-title > p {
  font-size: 1.4rem;
  color: rgba($navy-blue, .4);
  text-transform: uppercase;
}

.article-body {
  margin: 0 auto;
  line-height: 3.4rem;
  background-color: $navy-blue;
  color: white;
  padding: 20px;
}

.article-body h4 {
  font-size: 2.8rem;
  margin: 3rem 0 1rem;
  color: #fff;
}

.article-body a {
  color: #fff;
  border-bottom: 1px dashed #fff;
}