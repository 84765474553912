@import '../../App.scss';

.blog {
  max-width: 1024px;
  margin: 0 auto 80px;
  padding: 0 20px;

  @media #{$x-large} {
    padding: 0;
  }
}

.blog h2 {
  margin-top: 0;
  margin-bottom: 2rem;
  color: $navy-blue;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: center;

  @media #{$x-large} {
    font-size: 3.5rem;
    text-align: left;
  }
}

.blog-card {
  padding: 1.5rem 2rem;
  margin-top: 1rem;
  border: .1rem solid #ccc;
  border-left: .5rem solid $navy-blue;

  background-color: #fff;
  
  &:hover {
    padding-left: 60px;
  }

  transition: all .2s ease-in-out;

  @media #{$medium} {
    max-width: 90%;

    &:hover {
      max-width: 100%;
      border-color: $navy-blue;

      h3 {
        color: $navy-blue;
      }
    }
  }

}

.blog h3 {
  margin: 0;

  font-size: 2rem;
  font-family: $font-secondary;
  color: #414141;
  font-weight: $font-weight-light;

  transition: all .2s ease-in-out;

  @media #{$x-large} {
    font-size: 3rem;
  }
}

.blog p {
  margin: 0;
  
  color: darken(#b3b3b3, 20%);
  font-size: 1.6rem;
}

.blog-date {
  font-size: 1rem;
  color: $navy-blue;
}

