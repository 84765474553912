@import '../../../App.scss';

.pf-page {
  margin: 3rem 4rem;
  display: flex;
  flex-direction: column;

  @media #{$large} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.pf-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-column: 1/3;
}

.pf-info .pf-logo {
  width: 100%;
  max-width: 160px;
  margin: 0 auto 10px;
}

.pf-info h2 {
  margin: 0;
  color: $navy-blue;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  font-size: 3.5rem;
  margin-bottom: 20px;
}

.pf-btn {
  display: inline-block;
  padding: 1rem 2rem;
  margin: 1rem auto 3rem;
  border: 1px solid $navy-blue;


  background-color: $eggshell-white;

  color: $navy-blue;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-family: $font-main;
  
  cursor: pointer;

  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: $navy-blue;
    color: $eggshell-white;
  }
}

.left-panel {
  padding: 40px;
}

.left-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $navy-blue;
  color: $eggshell-white;
}

.left-panel h4 {
  color: $eggshell-white;
}

.right-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
}

.pf-copy {
  display: block;
  color: #fff;
  font-size: 20px;
}

.pf-feature-img {
  width: 100%;
  margin-bottom: 3rem;
}