@import '../../App.scss';

.about-page {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;

  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  padding: 20px;

  background-color: $navy-blue;

  color: $eggshell-white;

  @media #{$medium} {
    flex-flow: row nowrap;
  }
}

.about-info {
  flex: 5;
}

.about-title {
  margin-bottom: 1.5rem;
  margin-top: 0;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: $font-weight-light;
  text-align: center;
  
  @media #{$medium} {
    text-align: left;
    font-size: 3.5rem;
  }
}

.about-description {
  padding: 1rem 0;
  margin: 0;
  width: 100%;

  @media #{$medium} {
    width: 80%;
  }
}

.about-pic {
  padding: 2rem;
  margin: 0 auto;
  max-width: 250px;
  height: auto;

  @media #{$medium} {
    margin: 0;
    flex: 1;
    max-width: none;
  }
}

.abilities-section {
  @extend .about-info;
  flex: none;
  padding: 0;
  max-width: 1024px;
  margin: 30px auto 60px;

  background-color: transparent;
}

.abilities-title {
  @extend .about-title;
  color: $navy-blue;
  padding-left: 20px;
}

.abilities {
  margin: 1.5em 0;
  padding-left: 20px;

  @media #{$x-large} {
    padding: 0;
  }

  li {
    margin-bottom: 0.5em;
    padding: 0.5em 1em;
    color: #fff;
  }
}
[class^="ability-"] {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: "";
  }
  &:before {
    top: 0.5em;
    margin-left: -2em;
    text-align: right;
    color: #fff;
  }
  &:after {
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    background: $navy-blue;
    z-index: -1;
  }
}

$abilities: (
  html:        95,
  css:         95,
  javascript:  90,
  react:       90,
  angular:     90,
  sketch:      80,
);

@each $ability, $score in $abilities {
  @keyframes show-ability-#{$ability} {
    from { width: 0; }
    to { width: $score * 1%; }
  }
  .ability-#{$ability} {
    &:before {
      content: "#{$score}";
      left: $score * 1%;
    }
    &:after {
      animation: show-ability-#{$ability} ease-in-out 1s 1s 1;
      animation-fill-mode: forwards;
    }
  }
}
