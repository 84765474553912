@import '../../App.scss';

.contact-page {
  max-width: 1024px;
  margin: 0 auto 80px;

  color: $blue-gray-300;
}

.contact-title {
  margin-top: 0;
  margin-bottom: 2rem;
  color: $navy-blue;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: center;
  
  @media #{$x-large} {
    font-size: 3.5rem;
    text-align: left;
  }
}

.contact-page h3 {
  font-size: 2rem;
}

.contact-wrapper { 
  border: 1px solid $navy-blue; 
  margin: 0 20px;

  @media #{$x-large} {
    margin: 0;
  }
}

.contact-wrapper > * { padding: 1rem; }

.contact-info { background-color: $eggshell-white; }

.contact-form {
  background-color: #fff;
}

.contact-form ul { 
  margin: 0;
  padding: 0;
}

.contact-form li { 
  list-style-type: none; 

  > * {
    color: $navy-blue;
  }

  > :last-child {
    margin-left: 1rem;
  }
} 

.contact-info { 
  border-bottom:1px solid $blue-gray-600; 
  background-color: $navy-blue; 
}

.contact-info h3 {
  text-align: left;
  color: #fff;
  margin: 0;
}